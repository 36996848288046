import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'

import * as globalStyles from './../styles/Global.module.scss'
import * as styles from './../styles/Pedia.module.scss'
import { Link } from 'gatsby'
import { InfoCategoriesNav } from '../templates/ArticleList'

export interface PediaListProps {
  pageContext: {
    pedias: Pedia[]
  }
}

const PediaList = ({ pageContext: { pedias } }: PediaListProps) => {
  const allTags = pedias.reduce(function (map: { [key: string]: Pedia[] }, pedia: Pedia) {
    const typeName = pedia.pediaType?.typeName || '其它'
    if (!map[typeName]) map[typeName] = []
    map[typeName].push(pedia)
    return map
  }, {})

  return (
    <LayoutRoot>
      <>
        <InfoCategoriesNav />
        {Object.entries(allTags).map(([key, pedias]) => {
          return (
            <div key={key}>
              <div className={styles.tagTitle}>{key}</div>
              <div>
                {pedias.map((pedia) => (
                  <div key={`${key}-${pedia.id}`} className={styles.articleTitle}>
                    <Link to={`/pedia/${pedia.id}`} className={`uk-link-reset ${globalStyles.linkColor}`}>
                      {pedia.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </>
    </LayoutRoot>
  )
}

export default PediaList
